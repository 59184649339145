import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "5 MEO DMT - The game changer",
  "date": "2019-05-21T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`5meo = 5-methoxy-N N-dimethyltryptamine = C12H16N2O
DMT= N N-Dimethyltryptamine = C12H16N2`}</p>
    <p>{`Both of em fall under the tryptamine class, however, 5meo is a derivative of DMT itself whereas DMT is Substituted tryptamine. These duos are present everywhere; be it in plants, animals, or fungi, and also present in the cerebrospinal fluid of humans.`}</p>
    <p>{`Even though both of them exhibit structural and chemical similarities, the psychotropic effects produced in humans are different. I can take DMT all day w/o worrying about the experience since it produces a vivid and profound feeling. Even though we get a peak inter-dimensional visions, with improved awareness and Machine/DMT Elves encounters, One can experience and enjoy the profound natural state of life.`}</p>
    <p>{`Meanwhile, 5meo cuts all this mess and straightaway pushes one to the state of Nirvana/Samadi, which literally means Void / Nothingness. I would never call bufo a bad trip since there doesn't exist good/bad in this realm. The experience exhibited by 5meo is the ultimate reality, there is nothing beyond. You are left with a single choice, Surrender`}</p>
    <p>{`Also, 5meo is not recreational / party stuff neither it is recommended for psychedelic trips.IMO it works well for self-inquiry or realization, one should accept Severe ego diffusion while on 5meo which I honestly believe the majority can't bear ( that is called a bad trip).`}</p>
    <p>{`Anyway just realize the fact, that the peak 5meo experience is pretty much similar to the supreme state or reality.`}</p>
    <p>{`Buddha called it Shunyata,
Shiva called it Brahman,
Dionysus called it Luminous darkness`}</p>
    <p>{`Well, I am calling it Nirvana !!`}</p>
    <p>{`Stay safe and Happy tripping.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      